import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import React from "react"

const TermsOfAgreement = () => (
  <Layout pageInfo={{
    pageName: "privacy",
    pageTitle: "Algemene Voorwaarden",
    bannerTitle: "website-header-03.png",
  }}>
    <Seo title="Algemene Voorwaarden" />

    <section className="general-page" id="terms-and-conditions">
      <Container>
        <Row>
          <Col>
            <div className="section-heading">
              <h2 className="title">Artikel 1 - Toepasselijkheid</h2>
            </div>
            <div className="block">
              <p>
                Deze algemene voorwaarden zijn, tenzij anders overeengekomen, van toepassing op alle offertes en
                overeenkomsten van, of met Alles Kids Kindercoaching en alle daarmee verband houdende handelingen, zowel
                van voorbereidende als van uitvoerende aard. De onderhavige voorwaarden zijn eveneens van toepassing op
                alle overeenkomsten met opdrachtnemer, waarbij voor de uitvoering derden dienen te worden betrokken.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 2 - Bedrijfsomschrijving</h2>
            </div>
            <div className="block">
              <p>
                Alles Kids Kindercoaching is opgericht door Y.W. van Seters-Bogaard en gevestigd te Steenbergen. Alles
                Kids Kindercoaching is ingeschreven bij de Kamer van Koophandel onder nummer 82490651.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 3 - Definities</h2>
            </div>

            <div className="block">
              <p>
                <ul>
                  <li> a. Opdrachtnemer: Alles Kids Kindercoaching.</li>
                  <li> b. Opdrachtgever: de wederpartij van opdrachtnemer, te weten de cliënt.</li>
                  <li> c. Overeenkomst: een mondelinge dan wel schriftelijke overeenkomst tussen opdrachtgever en
                    opdrachtnemer
                    betreffende een overeengekomen levering van diensten of goederen.
                  </li>
                </ul>
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 4 - De overeenkomst</h2>
            </div>
            <div className="block">
              <p>
                Voor kinderen onder de 17 jaar geldt dat, indien van toepassing, beide gezaghebbende ouders akkoord
                moeten gaan met de begeleiding van hun kind. Daarom moet de coachingsovereenkomst worden getekend door
                de gezaghebbende ouder(s) en Y.W. van Seters-Bogaard, waarmee toestemming wordt gegeven voor de
                begeleiding van hun kind. De opdrachtgevende gezaghebbende ouder draagt er zorg voor dat de andere
                gezaghebbende ouder wordt geïnformeerd over de begeleiding en hier zijn/haar toestemming voor geeft.
                Hij/zij gaat ermee akkoord dat, indien de andere ouder op enigerlei wijze bezwaren maakt tegen de
                coaching of anderszins, hij/zij de consequenties daarvan op zich neemt. Alles Kids Kindercoaching kan
                hier niet verantwoordelijk voor worden gehouden.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 5 - Uitvoering van de overeenkomst</h2>
            </div>
            <div className="block">
              <p>
                <ul>
                  <li> a. De opdrachtnemer zal de overeenkomst naar beste inzicht en vermogen en in overeenstemming met
                    de
                    eisen van goed vakmanschap uitvoeren.
                  </li>
                  <li> b. De opdrachtnemer heeft een inspanningsverplichting naar de opdrachtgever, nooit een
                    resultaatverplichting.
                  </li>
                </ul>
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 6 - Tarieven</h2>
            </div>
            <div className="block">
              <p>
                Opdrachtgever dient op de hoogte te zijn van de kosten. Deze zijn na te lezen op de website of na te
                vragen bij Alles Kids Kindercoaching. Kort telefonisch overleg is gratis, als er meer tijd nodig is voor
                een gesprek zal een afspraak worden gemaakt waarbij de tarieven zoals beschreven op de website
                gehanteerd worden.
                Tenzij nadrukkelijk anders vermeld zijn alle bedragen in Euro’s. Op alle tarieven van opdrachtnemer is
                BWT verschuldigd.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 7 - Betalingsvoorwaarden</h2>
            </div>
            <div className="block">
              <p>
                Voor de betaling ontvangt de opdrachtgever een factuur. Deze dient binnen 7 dagen na factuurdatum
                betaald te worden. Indien de opdrachtgever niet binnen de termijn van 7 dagen betaalt wordt een
                betalingsherinnering verstuurd. Indien daarna weer niet binnen de betalingstermijn wordt betaald is de
                opdrachtgever gerechtigd verdere levering van diensten of goederen op te schorten.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 8 - Verhindering deelname </h2>
            </div>
            <div className="block">
              <p>
                Indien bij verhindering de deelname aan een coachingssessie tenminste 24 uur van tevoren wordt afgezegd
                wordt de gereserveerde tijd niet in rekening gebracht. Bij afzeggen binnen 24 uur worden de kosten van
                de sessie gefactureerd. Bij ziekte of overige bijzondere omstandigheden is overleg mogelijk.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 9 - Overmacht</h2>
            </div>
            <div className="block">
              <p>
                Onder overmacht wordt verstaan alle van buiten komende oorzaken, voorzien of niet voorzien, waarop
                opdrachtnemer geen invloed kan uitoefenen doch waardoor opdrachtnemer niet in staat is de verplichtingen
                na te komen. Opdrachtnemer heeft ook het recht zich op overmacht te beroepen indien de omstandigheid die
                nakoming verhindert intreedt nadat de opdrachtnemer is gestart met uitvoering van de overeenkomst.
                Tijdens overmacht worden verplichtingen van opdrachtnemer opgeschort. Indien de periode waarin door
                overmacht nakoming van de verplichtingen door de opdrachtnemer langer duurt dan 2 maanden zijn beide
                partijen bevoegd de overeenkomst te ontbinden zonder dat er in dat geval een verplichting tot
                schadevergoeding bestaat.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 10 - Aansprakelijkheid</h2>
            </div>
            <div className="block">
              <p>
                Bij lichamelijke en psychische klachten raadt opdrachtnemer nadrukkelijk aan om eerst een arts of
                psycholoog te raadplegen.
                Opdrachtnemer is nimmer aansprakelijk voor directe schade of indirecte schade, emotionele schade of
                schade voortvloeiend uit beslissingen die opdrachtgever, als dan niet in overleg met opdrachtnemer,
                heeft genomen. Opdrachtgever is ten alle tijde verantwoordelijk voor gemaakte keuzes, diens eigen gedrag
                en de consequenties hiervan, zowel gedurende de periode van de coachingssessies als de periode daarna.
                De aansprakelijkheid van Alles Kids Kindercoaching is ten alle tijde beperkt tot maximaal het bedrag dat
                is bepaald aan Alles Kids Kindercoaching/Y.W. van Seters-Bogaard in het kader van de
                coachingsovereenkomst.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 11 - Klachtenprocedure</h2>
            </div>
            <div className="block">
              <p>
                Klachten voorafgaand aan, tijdens of na de verrichte diensten over de verrichte diensten dienen door de
                opdrachtgever binnen 8 dagen na de afspraak schriftelijk te worden gemeld aan de opdrachtnemer. De
                klacht dient te worden voorzien van een zo gedetailleerd mogelijke omschrijving zodat de opdrachtnemer
                in staat is zo adequaat mogelijk te reageren.
                Indien geen overeenstemming wordt bereikt is de rechter in de woonplaats van de opdrachtnemer bij
                uitsluiting bevoegd om van de geschillen kennis te nemen.
              </p>
            </div>

            <div className="section-heading">
              <h2 className="title">Artikel 12 - Vertrouwelijke informatie</h2>
            </div>
            <div className="block">
              <p>
                Alle informatie die tijdens coachingssessies wordt besproken zal vertrouwelijk worden behandeld. In
                overleg met de opdrachtgever kan besloten worden derden in te lichten.
                In geval van dreigend gevaar voor de opdrachtgever, opdrachtnemer, bepaalde personen of de samenleving
                behoudt de opdrachtnemer zich het recht voor om relevante informatie te verstrekken aan bevoegde
                instanties of personen zodat het gevaar kan worden voorkomen.
                Indien opdrachtnemer op grond van een wettelijke bepaling of een rechterlijke uitspraak aangewezen wordt
                om toch vertrouwelijke informatie te verstrekken aan derden kan opdrachtgever geen schadevergoeding of
                schadeloosstelling van de opdrachtnemer eisen.
              </p>
            </div>
            <div className="section-heading">
              <h2 className="title">Artikel 13 - Wetgeving</h2>
            </div>
            <div className="block">
              <p>
                Alleen het Nederlands recht is van toepassing op alle overeenkomsten en diensten tussen opdrachtgever en
                opdrachtnemer.
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default TermsOfAgreement

